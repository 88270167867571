var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "has",
              rawName: "v-has",
              value: "organization:department:list:page:view",
              expression: "'organization:department:list:page:view'",
            },
          ],
        },
        [
          false
            ? _c(
                "a-dropdown",
                {
                  attrs: {
                    trigger: [this.dropTrigger],
                    visible: this.dropTrigger == "contextmenu",
                  },
                  on: { visibleChange: _vm.dropStatus },
                },
                [
                  _c(
                    "span",
                    _vm._l(_vm.departTree, function (root) {
                      return _c(
                        "div",
                        { key: root.key, staticClass: "tree-box" },
                        [
                          _c("div", { staticClass: "tree-row root-tree-row" }, [
                            _c("div", { staticClass: "tree-item" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "content",
                                  on: {
                                    contextmenu: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleRightClick(root.key)
                                    },
                                    dblclick: function ($event) {
                                      return _vm.handleDoubleClick(root.key)
                                    },
                                  },
                                },
                                [
                                  _vm.isEdited(root.key)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex items-center",
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _c(
                                            "a-input-group",
                                            { attrs: { size: "large" } },
                                            [
                                              _c(
                                                "a-row",
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 4 } },
                                                    [
                                                      _c("a-input-number", {
                                                        attrs: { min: 1 },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.enterEdit.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            root.departOrder,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              root,
                                                              "departOrder",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "root.departOrder",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 20 } },
                                                    [
                                                      _c("a-input", {
                                                        ref: "inputNode",
                                                        refInFor: true,
                                                        staticClass:
                                                          "insert-input",
                                                        attrs: { type: "text" },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.enterEdit.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            root.inputTitle,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              root,
                                                              "inputTitle",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "root.inputTitle",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("a-icon", {
                                            staticClass: "ml-3",
                                            attrs: { type: "check" },
                                            on: { click: _vm.enterEdit },
                                          }),
                                          _c("a-icon", {
                                            staticClass: "ml-3",
                                            attrs: { type: "close" },
                                            on: { click: _vm.cancelEditMode },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-medium tree-node-text",
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              root.departOrder
                                                ? root.departOrder +
                                                    "." +
                                                    root.title
                                                : root.title
                                            )
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                              root.children
                                ? _c("div", { staticClass: "vertical-line" })
                                : _vm._e(),
                            ]),
                          ]),
                          root.children
                            ? _c(
                                "div",
                                {
                                  class: [
                                    "tree-row",
                                    "two-tree-row",
                                    {
                                      "no-hor-line": root.children.length == 1,
                                    },
                                  ],
                                },
                                _vm._l(root.children, function (child) {
                                  return _c(
                                    "div",
                                    {
                                      key: child.key,
                                      staticClass: "tree-item",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "vertical-line",
                                      }),
                                      _c("div", { staticClass: "content" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tree-row root-tree-row",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "tree-item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "content",
                                                    on: {
                                                      contextmenu: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return _vm.handleRightClick(
                                                          child.key
                                                        )
                                                      },
                                                      dblclick: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleDoubleClick(
                                                          child.key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.isEdited(child.key)
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex items-center",
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _c(
                                                              "a-input-group",
                                                              {
                                                                attrs: {
                                                                  size: "large",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a-row",
                                                                  [
                                                                    _c(
                                                                      "a-col",
                                                                      {
                                                                        attrs: {
                                                                          span: 4,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-input-number",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                min: 1,
                                                                              },
                                                                            on: {
                                                                              keyup:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "enter",
                                                                                      13,
                                                                                      $event.key,
                                                                                      "Enter"
                                                                                    )
                                                                                  )
                                                                                    return null
                                                                                  return _vm.enterEdit.apply(
                                                                                    null,
                                                                                    arguments
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  child.departOrder,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      child,
                                                                                      "departOrder",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "child.departOrder",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "a-col",
                                                                      {
                                                                        attrs: {
                                                                          span: 20,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-input",
                                                                          {
                                                                            ref: "inputNode",
                                                                            refInFor: true,
                                                                            staticClass:
                                                                              "insert-input",
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                              },
                                                                            on: {
                                                                              keyup:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "enter",
                                                                                      13,
                                                                                      $event.key,
                                                                                      "Enter"
                                                                                    )
                                                                                  )
                                                                                    return null
                                                                                  return _vm.enterEdit.apply(
                                                                                    null,
                                                                                    arguments
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  child.inputTitle,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      child,
                                                                                      "inputTitle",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "child.inputTitle",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("a-icon", {
                                                              staticClass:
                                                                "ml-3",
                                                              attrs: {
                                                                type: "check",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.enterEdit,
                                                              },
                                                            }),
                                                            _c("a-icon", {
                                                              staticClass:
                                                                "ml-3",
                                                              attrs: {
                                                                type: "close",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.cancelEditMode,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tree-node-text",
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                child.departOrder
                                                                  ? child.departOrder +
                                                                      "." +
                                                                      child.title
                                                                  : child.title
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                                child.children
                                                  ? _c("div", {
                                                      staticClass:
                                                        "vertical-line",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        child.children
                                          ? _c(
                                              "div",
                                              {
                                                class: [
                                                  "tree-row",
                                                  "two-tree-row",
                                                  "three-tree-row",
                                                  {
                                                    "no-hor-line":
                                                      child.children.length ==
                                                      1,
                                                  },
                                                ],
                                              },
                                              _vm._l(
                                                child.children,
                                                function (third) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: third.key,
                                                      staticClass: "tree-item",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "vertical-line",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "content",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "title",
                                                              on: {
                                                                contextmenu:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.handleRightClick(
                                                                      third.key
                                                                    )
                                                                  },
                                                                dblclick:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDoubleClick(
                                                                      third.key
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.isEdited(
                                                                third.key
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "flex items-center",
                                                                      attrs: {
                                                                        slot: "title",
                                                                      },
                                                                      slot: "title",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a-input-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "large",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-row",
                                                                            [
                                                                              _c(
                                                                                "a-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      span: 4,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "a-input-number",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          min: 1,
                                                                                        },
                                                                                      on: {
                                                                                        keyup:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            if (
                                                                                              !$event.type.indexOf(
                                                                                                "key"
                                                                                              ) &&
                                                                                              _vm._k(
                                                                                                $event.keyCode,
                                                                                                "enter",
                                                                                                13,
                                                                                                $event.key,
                                                                                                "Enter"
                                                                                              )
                                                                                            )
                                                                                              return null
                                                                                            return _vm.enterEdit.apply(
                                                                                              null,
                                                                                              arguments
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            third.departOrder,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                third,
                                                                                                "departOrder",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "third.departOrder",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "a-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      span: 20,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "a-input",
                                                                                    {
                                                                                      ref: "inputNode",
                                                                                      refInFor: true,
                                                                                      staticClass:
                                                                                        "insert-input",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "text",
                                                                                        },
                                                                                      on: {
                                                                                        keyup:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            if (
                                                                                              !$event.type.indexOf(
                                                                                                "key"
                                                                                              ) &&
                                                                                              _vm._k(
                                                                                                $event.keyCode,
                                                                                                "enter",
                                                                                                13,
                                                                                                $event.key,
                                                                                                "Enter"
                                                                                              )
                                                                                            )
                                                                                              return null
                                                                                            return _vm.enterEdit.apply(
                                                                                              null,
                                                                                              arguments
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            third.inputTitle,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                third,
                                                                                                "inputTitle",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "third.inputTitle",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "a-icon",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs:
                                                                            {
                                                                              type: "check",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.enterEdit,
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "a-icon",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs:
                                                                            {
                                                                              type: "close",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.cancelEditMode,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "tree-node-text",
                                                                      attrs: {
                                                                        slot: "title",
                                                                      },
                                                                      slot: "title",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          third.title
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "platform-list",
                                                            },
                                                            _vm._l(
                                                              third.platforms,
                                                              function (
                                                                platform
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: platform.key,
                                                                    staticClass:
                                                                      "platform-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "tree-node-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              platform.departOrder
                                                                                ? platform.departOrder +
                                                                                    "." +
                                                                                    platform.title
                                                                                : platform.title
                                                                            ) +
                                                                            "\n                          "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      this.currSelected.level < 3
                        ? _c(
                            "a-menu-item",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value:
                                    "organization:department:list:button:add",
                                  expression:
                                    "'organization:department:list:button:add'",
                                },
                              ],
                              key: "1",
                              attrs: { icon: "plus" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAdd(3)
                                },
                              },
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e(),
                      _c(
                        "a-menu-item",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "organization:department:list:button:edit",
                              expression:
                                "'organization:department:list:button:edit'",
                            },
                          ],
                          key: "4",
                          on: { click: _vm.handleEdit },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-menu-item",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value:
                                "organization:department:list:button:delete",
                              expression:
                                "'organization:department:list:button:delete'",
                            },
                          ],
                          key: "2",
                          on: { click: _vm.handleDelete },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "div",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd(1)
                        },
                      },
                    },
                    [_vm._v("添加部门")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd(2)
                        },
                      },
                    },
                    [_vm._v("添加下级")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "download" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExportXls("部门信息")
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        name: "file",
                        showUploadList: false,
                        multiple: false,
                        headers: _vm.tokenHeader,
                        action: _vm.importExcelUrl,
                      },
                      on: { change: _vm.handleImportExcel },
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", icon: "import" } },
                        [_vm._v("导入")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { title: "删除多条数据", type: "default" },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("批量删除")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { on: { click: _vm.closeDropdownMenu } },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c("a-col", { attrs: { md: 24, sm: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "100%", overflow: "auto" } },
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: {
                              trigger: [this.dropTrigger],
                              visible: this.dropTrigger == "contextmenu",
                            },
                            on: { visibleChange: _vm.dropStatus },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "user-select": "none" } },
                              [
                                _c(
                                  "a-tree",
                                  {
                                    attrs: {
                                      defaultExpandParent: true,
                                      selectedKeys: _vm.selectedKeys,
                                      checkedKeys: _vm.checkedKeys,
                                      checkStrictly: _vm.checkStrictly,
                                      expandedKeys: _vm.iExpandedKeys,
                                      autoExpandParent: _vm.autoExpandParent,
                                    },
                                    on: {
                                      select: _vm.onSelect,
                                      check: _vm.onCheck,
                                      rightClick: _vm.rightClickHandle,
                                      dblclick: _vm.handleDoubleClickNode,
                                      expand: _vm.onExpand,
                                    },
                                  },
                                  _vm._l(_vm.departTree, function (root) {
                                    return _c(
                                      "a-tree-node",
                                      {
                                        key: root.key,
                                        attrs: { nodeTitle: "root.title" },
                                      },
                                      [
                                        _vm.isEdited(root.key)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex items-center",
                                                attrs: { slot: "title" },
                                                slot: "title",
                                              },
                                              [
                                                _c(
                                                  "a-input-group",
                                                  { attrs: { size: "large" } },
                                                  [
                                                    _c(
                                                      "a-row",
                                                      [
                                                        _c(
                                                          "a-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _c(
                                                              "a-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 1,
                                                                },
                                                                on: {
                                                                  keyup:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      )
                                                                        return null
                                                                      return _vm.enterEdit.apply(
                                                                        null,
                                                                        arguments
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    root.departOrder,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        root,
                                                                        "departOrder",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "root.departOrder",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "a-col",
                                                          {
                                                            attrs: { span: 20 },
                                                          },
                                                          [
                                                            _c("a-input", {
                                                              ref: "inputNode",
                                                              refInFor: true,
                                                              staticClass:
                                                                "insert-input",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                keyup:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    return _vm.enterEdit.apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  root.inputTitle,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      root,
                                                                      "inputTitle",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "root.inputTitle",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("a-icon", {
                                                  staticClass: "ml-3",
                                                  attrs: { type: "check" },
                                                  on: { click: _vm.enterEdit },
                                                }),
                                                _c("a-icon", {
                                                  staticClass: "ml-3",
                                                  attrs: { type: "close" },
                                                  on: {
                                                    click: _vm.cancelEditMode,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-medium tree-node-text first-tree-text",
                                                attrs: { slot: "title" },
                                                slot: "title",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    root.departOrder
                                                      ? root.departOrder +
                                                          "." +
                                                          root.title
                                                      : root.title
                                                  )
                                                ),
                                              ]
                                            ),
                                        _vm._l(root.children, function (child) {
                                          return _c(
                                            "a-tree-node",
                                            {
                                              key: child.key,
                                              staticClass: "two-tree-row",
                                              attrs: { nodeTitle: child.title },
                                            },
                                            [
                                              _vm.isEdited(child.key)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex items-center",
                                                      attrs: { slot: "title" },
                                                      slot: "title",
                                                    },
                                                    [
                                                      _c(
                                                        "a-input-group",
                                                        {
                                                          attrs: {
                                                            size: "large",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  attrs: {
                                                                    span: 4,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-input-number",
                                                                    {
                                                                      attrs: {
                                                                        min: 1,
                                                                      },
                                                                      on: {
                                                                        keyup:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              !$event.type.indexOf(
                                                                                "key"
                                                                              ) &&
                                                                              _vm._k(
                                                                                $event.keyCode,
                                                                                "enter",
                                                                                13,
                                                                                $event.key,
                                                                                "Enter"
                                                                              )
                                                                            )
                                                                              return null
                                                                            return _vm.enterEdit.apply(
                                                                              null,
                                                                              arguments
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          child.departOrder,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              child,
                                                                              "departOrder",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "child.departOrder",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  attrs: {
                                                                    span: 20,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-input",
                                                                    {
                                                                      ref: "inputNode",
                                                                      refInFor: true,
                                                                      staticClass:
                                                                        "insert-input",
                                                                      attrs: {
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        keyup:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              !$event.type.indexOf(
                                                                                "key"
                                                                              ) &&
                                                                              _vm._k(
                                                                                $event.keyCode,
                                                                                "enter",
                                                                                13,
                                                                                $event.key,
                                                                                "Enter"
                                                                              )
                                                                            )
                                                                              return null
                                                                            return _vm.enterEdit.apply(
                                                                              null,
                                                                              arguments
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          child.inputTitle,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              child,
                                                                              "inputTitle",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "child.inputTitle",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("a-icon", {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          type: "check",
                                                        },
                                                        on: {
                                                          click: _vm.enterEdit,
                                                        },
                                                      }),
                                                      _c("a-icon", {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          type: "close",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.cancelEditMode,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tree-node-text two-tree-node-text",
                                                      attrs: { slot: "title" },
                                                      slot: "title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          child.departOrder
                                                            ? child.departOrder +
                                                                "." +
                                                                child.title
                                                            : child.title
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                              _vm._l(
                                                child.children,
                                                function (third) {
                                                  return _c(
                                                    "a-tree-node",
                                                    {
                                                      key: third.key,
                                                      staticClass:
                                                        "platforms-tree",
                                                      attrs: {
                                                        nodeTitle: third.title,
                                                      },
                                                    },
                                                    [
                                                      _vm.isEdited(third.key)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex items-center",
                                                              attrs: {
                                                                slot: "title",
                                                              },
                                                              slot: "title",
                                                            },
                                                            [
                                                              _c(
                                                                "a-input-group",
                                                                {
                                                                  attrs: {
                                                                    size: "large",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-row",
                                                                    [
                                                                      _c(
                                                                        "a-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              span: 4,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-input-number",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  min: 1,
                                                                                },
                                                                              on: {
                                                                                keyup:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      !$event.type.indexOf(
                                                                                        "key"
                                                                                      ) &&
                                                                                      _vm._k(
                                                                                        $event.keyCode,
                                                                                        "enter",
                                                                                        13,
                                                                                        $event.key,
                                                                                        "Enter"
                                                                                      )
                                                                                    )
                                                                                      return null
                                                                                    return _vm.enterEdit.apply(
                                                                                      null,
                                                                                      arguments
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    third.departOrder,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        third,
                                                                                        "departOrder",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "third.departOrder",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "a-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              span: 20,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-input",
                                                                            {
                                                                              ref: "inputNode",
                                                                              refInFor: true,
                                                                              staticClass:
                                                                                "insert-input",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                              on: {
                                                                                keyup:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      !$event.type.indexOf(
                                                                                        "key"
                                                                                      ) &&
                                                                                      _vm._k(
                                                                                        $event.keyCode,
                                                                                        "enter",
                                                                                        13,
                                                                                        $event.key,
                                                                                        "Enter"
                                                                                      )
                                                                                    )
                                                                                      return null
                                                                                    return _vm.enterEdit.apply(
                                                                                      null,
                                                                                      arguments
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    third.inputTitle,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        third,
                                                                                        "inputTitle",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "third.inputTitle",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("a-icon", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  type: "check",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.enterEdit,
                                                                },
                                                              }),
                                                              _c("a-icon", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  type: "close",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.cancelEditMode,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tree-node-text platforms-tree-node-text",
                                                              attrs: {
                                                                slot: "title",
                                                              },
                                                              slot: "title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  third.departOrder
                                                                    ? third.departOrder +
                                                                        "." +
                                                                        third.title
                                                                    : third.title
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                      _vm._l(
                                                        third.platforms,
                                                        function (pf) {
                                                          return _c(
                                                            "a-tree-node",
                                                            {
                                                              key: pf.key,
                                                              attrs: {
                                                                checkable: false,
                                                                nodeTitle:
                                                                  pf.title,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tree-node-text",
                                                                  attrs: {
                                                                    slot: "title",
                                                                  },
                                                                  slot: "title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      pf.title
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        }),
                                      ],
                                      2
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                this.currSelected.level < 3
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        directives: [
                                          {
                                            name: "has",
                                            rawName: "v-has",
                                            value:
                                              "organization:department:list:button:add",
                                            expression:
                                              "'organization:department:list:button:add'",
                                          },
                                        ],
                                        key: "1",
                                        attrs: { icon: "plus" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdd(3)
                                          },
                                        },
                                      },
                                      [_vm._v("添加")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a-menu-item",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value:
                                          "organization:department:list:button:edit",
                                        expression:
                                          "'organization:department:list:button:edit'",
                                      },
                                    ],
                                    key: "4",
                                    on: { click: _vm.handleEdit },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value:
                                          "organization:department:list:button:delete",
                                        expression:
                                          "'organization:department:list:button:delete'",
                                      },
                                    ],
                                    key: "2",
                                    on: { click: _vm.handleDelete },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("depart-modal", { ref: "departModal", on: { ok: _vm.loadTree } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }