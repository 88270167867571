<template xmlns:background-color="http://www.w3.org/1999/xhtml">
  <body-layout>
    <div v-has="'organization:department:list:page:view'">
      <!-- 重新组织部门树结构 -->
      <a-dropdown
        v-if="false"
        :trigger="[this.dropTrigger]"
        :visible="this.dropTrigger == 'contextmenu'"
        @visibleChange="dropStatus"
      >
        <span>
          <div class="tree-box" v-for="root in departTree" :key="root.key">
            <div class="tree-row root-tree-row">
              <div class="tree-item">
                <div
                  class="content"
                  @click.right.prevent="handleRightClick(root.key)"
                  @dblclick="handleDoubleClick(root.key)"
                >
                  <div class="flex items-center" v-if="isEdited(root.key)" slot="title">
                    <a-input-group size="large">
                      <a-row>
                        <a-col :span="4">
                          <a-input-number @keyup.enter="enterEdit" :min="1" v-model="root.departOrder" />
                        </a-col>
                        <a-col :span="20">
                          <a-input
                            class="insert-input"
                            type="text"
                            v-model="root.inputTitle"
                            @keyup.enter="enterEdit"
                            ref="inputNode"
                          />
                        </a-col>
                      </a-row>
                    </a-input-group>

                    <a-icon type="check" @click="enterEdit" class="ml-3" />
                    <a-icon type="close" @click="cancelEditMode" class="ml-3" />
                  </div>
                  <span slot="title" class="font-medium tree-node-text" v-else>{{ root.departOrder?(root.departOrder+'.'+root.title):root.title }}</span>
                </div>
                <div class="vertical-line" v-if="root.children"></div>
              </div>
            </div>
            <div
              v-if="root.children"
              :class="['tree-row', 'two-tree-row', { 'no-hor-line': root.children.length == 1 }]"
            >
              <div class="tree-item" v-for="child in root.children" :key="child.key">
                <div class="vertical-line"></div>
                <div class="content">
                  <div class="tree-row root-tree-row">
                    <div class="tree-item">
                      <div
                        class="content"
                        @click.right.prevent="handleRightClick(child.key)"
                        @dblclick="handleDoubleClick(child.key)"
                      >
                        <div class="flex items-center" slot="title" v-if="isEdited(child.key)">
                          <a-input-group size="large">
                            <a-row>
                              <a-col :span="4">
                                <a-input-number :min="1" @keyup.enter="enterEdit" v-model="child.departOrder" />
                              </a-col>
                              <a-col :span="20">
                                <a-input
                                  class="insert-input"
                                  type="text"
                                  v-model="child.inputTitle"
                                  @keyup.enter="enterEdit"
                                  ref="inputNode"
                                />
                              </a-col>
                            </a-row>
                          </a-input-group>
                          <a-icon type="check" @click="enterEdit" class="ml-3" />
                          <a-icon type="close" @click="cancelEditMode" class="ml-3" />
                        </div>
                        <span slot="title" class="tree-node-text" v-else>{{ child.departOrder?(child.departOrder+'.'+child.title):child.title }}</span>
                      </div>
                      <div class="vertical-line" v-if="child.children"></div>
                    </div>
                  </div>
                  <div
                    v-if="child.children"
                    :class="[
                      'tree-row',
                      'two-tree-row',
                      'three-tree-row',
                      { 'no-hor-line': child.children.length == 1 },
                    ]"
                  >
                    <div class="tree-item" v-for="third in child.children" :key="third.key">
                      <div class="vertical-line"></div>
                      <div class="content">
                        <div
                          class="title"
                          @click.right.prevent="handleRightClick(third.key)"
                          @dblclick="handleDoubleClick(third.key)"
                        >
                          <div class="flex items-center" v-if="isEdited(third.key)" slot="title">
                            <a-input-group size="large">
                              <a-row>
                                <a-col :span="4">
                                  <a-input-number :min="1" @keyup.enter="enterEdit" v-model="third.departOrder" />
                                </a-col>
                                <a-col :span="20">
                                  <a-input
                                    class="insert-input"
                                    type="text"
                                    v-model="third.inputTitle"
                                    @keyup.enter="enterEdit"
                                    ref="inputNode"
                                  />
                                </a-col>
                              </a-row>
                            </a-input-group>
                            <a-icon type="check" @click="enterEdit" class="ml-3" />
                            <a-icon type="close" @click="cancelEditMode" class="ml-3" />
                          </div>
                          <span slot="title" class="tree-node-text" v-else>{{ third.title }}</span>
                        </div>
                        <div class="platform-list">
                          <div class="platform-item" v-for="platform in third.platforms" :key="platform.key">
                            <span class="tree-node-text">
                              {{ platform.departOrder?(platform.departOrder+'.'+platform.title):platform.title }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
        <!--新增右键点击事件,和增加添加和删除功能-->
        <a-menu slot="overlay">
          <a-menu-item
            @click="handleAdd(3)"
            key="1"
            v-if="this.currSelected.level < 3"
            icon="plus"
            v-has="'organization:department:list:button:add'"
            >添加</a-menu-item
          >
          <a-menu-item @click="handleEdit" key="4" v-has="'organization:department:list:button:edit'">编辑</a-menu-item>
          <a-menu-item @click="handleDelete" key="2" v-has="'organization:department:list:button:delete'"
            >删除</a-menu-item
          >
          <!-- <a-menu-item @click="closeDropdownMenu" key="3">取消</a-menu-item> -->
        </a-menu>
      </a-dropdown>

      <!-- 按钮操作区域 -->
      <div v-if="false">
        <a-button @click="handleAdd(1)" icon="plus" type="primary">添加部门</a-button>
        <a-button @click="handleAdd(2)" icon="plus" type="primary">添加下级</a-button>
        <a-button type="primary" icon="download" @click="handleExportXls('部门信息')">导出</a-button>
        <a-upload
          name="file"
          :showUploadList="false"
          :multiple="false"
          :headers="tokenHeader"
          :action="importExcelUrl"
          @change="handleImportExcel"
        >
          <a-button type="primary" icon="import">导入</a-button>
        </a-upload>
        <a-button title="删除多条数据" @click="batchDel" type="default">批量删除</a-button>
        <!--<a-button @click="refresh" type="default" icon="reload" :loading="loading">刷新</a-button>-->
      </div>
      <div style="" @click="closeDropdownMenu">
        <!-- <a-input-search @search="onSearch" class="my-4" placeholder="请输入部门名称"/> -->
        <!-- 树-->
        <a-row :gutter="24">
          <a-col :md="24" :sm="24">
            <div style="width: 100%; overflow: auto">
              <a-dropdown
                :trigger="[this.dropTrigger]"
                :visible="this.dropTrigger == 'contextmenu'"
                @visibleChange="dropStatus"
              >
                <span style="user-select: none">
                  <a-tree
                    @select="onSelect"
                    @check="onCheck"
                    @rightClick="rightClickHandle"
                    @dblclick="handleDoubleClickNode"
                    :defaultExpandParent="true"
                    :selectedKeys="selectedKeys"
                    :checkedKeys="checkedKeys"
                    :checkStrictly="checkStrictly"
                    :expandedKeys="iExpandedKeys"
                    :autoExpandParent="autoExpandParent"
                    @expand="onExpand"
                  >
                    <a-tree-node v-for="root in departTree" :key="root.key" nodeTitle="root.title">
                      <div class="flex items-center" v-if="isEdited(root.key)" slot="title">
                        <a-input-group size="large">
                          <a-row>
                            <a-col :span="4">
                              <a-input-number :min="1" @keyup.enter="enterEdit" v-model="root.departOrder" />
                            </a-col>
                            <a-col :span="20">
                              <a-input
                                class="insert-input"
                                type="text"
                                v-model="root.inputTitle"
                                @keyup.enter="enterEdit"
                                ref="inputNode"
                              />
                            </a-col>
                          </a-row>
                        </a-input-group>
                        <a-icon type="check" @click="enterEdit" class="ml-3" />
                        <a-icon type="close" @click="cancelEditMode" class="ml-3" />
                      </div>
                      <span slot="title" class="font-medium tree-node-text first-tree-text" v-else>{{
                        root.departOrder?(root.departOrder+'.'+root.title):root.title
                      }}</span>
                      <a-tree-node
                        class="two-tree-row"
                        v-for="child in root.children"
                        :key="child.key"
                        :nodeTitle="child.title"
                      >
                        <div class="flex items-center" slot="title" v-if="isEdited(child.key)">
                          <a-input-group size="large">
                            <a-row>
                              <a-col :span="4">
                                <a-input-number :min="1" @keyup.enter="enterEdit" v-model="child.departOrder" />
                              </a-col>
                              <a-col :span="20">
                                <a-input
                                  class="insert-input"
                                  type="text"
                                  v-model="child.inputTitle"
                                  @keyup.enter="enterEdit"
                                  ref="inputNode"
                                />
                              </a-col>
                            </a-row>
                          </a-input-group>
                          <a-icon type="check" @click="enterEdit" class="ml-3" />
                          <a-icon type="close" @click="cancelEditMode" class="ml-3" />
                        </div>
                        <span slot="title" class="tree-node-text two-tree-node-text" v-else>{{ child.departOrder?(child.departOrder+'.'+child.title):child.title }}</span>
                        <a-tree-node
                          class="platforms-tree"
                          v-for="third in child.children"
                          :nodeTitle="third.title"
                          :key="third.key"
                        >
                          <div class="flex items-center" v-if="isEdited(third.key)" slot="title">
                            <a-input-group size="large">
                              <a-row>
                                <a-col :span="4">
                                  <a-input-number :min="1" @keyup.enter="enterEdit" v-model="third.departOrder" />
                                </a-col>
                                <a-col :span="20">
                                  <a-input
                                    class="insert-input"
                                    type="text"
                                    v-model="third.inputTitle"
                                    @keyup.enter="enterEdit"
                                    ref="inputNode"
                                  />
                                </a-col>
                              </a-row>
                            </a-input-group>
                            <a-icon type="check" @click="enterEdit" class="ml-3" />
                            <a-icon type="close" @click="cancelEditMode" class="ml-3" />
                          </div>
                          <span slot="title" class="tree-node-text platforms-tree-node-text" v-else>{{ third.departOrder?(third.departOrder+'.'+third.title):third.title }}</span>
                          <a-tree-node
                            :checkable="false"
                            v-for="pf in third.platforms"
                            :nodeTitle="pf.title"
                            :key="pf.key"
                          >
                            <span slot="title" class="tree-node-text">{{ pf.title }}</span>
                          </a-tree-node>
                        </a-tree-node>
                      </a-tree-node>
                    </a-tree-node>
                  </a-tree>
                </span>
                <!--新增右键点击事件,和增加添加和删除功能-->
                <a-menu slot="overlay">
                  <a-menu-item
                    @click="handleAdd(3)"
                    key="1"
                    v-if="this.currSelected.level < 3"
                    icon="plus"
                    v-has="'organization:department:list:button:add'"
                    >添加</a-menu-item
                  >
                  <a-menu-item @click="handleEdit" key="4" v-has="'organization:department:list:button:edit'"
                    >编辑</a-menu-item
                  >
                  <a-menu-item @click="handleDelete" key="2" v-has="'organization:department:list:button:delete'"
                    >删除</a-menu-item
                  >
                  <!-- <a-menu-item @click="closeDropdownMenu" key="3">取消</a-menu-item> -->
                </a-menu>
              </a-dropdown>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <depart-modal ref="departModal" @ok="loadTree"></depart-modal>
  </body-layout>
</template>
<script>
import DepartModal from './modules/DepartModal'
import pick from 'lodash.pick'
import { queryDepartTreeList, searchByKeywords, deleteByDepartId } from '@/api/api'
import { httpAction, deleteAction, getAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import DepartAuthModal from './modules/DepartAuthModal'
import { checkPermission } from '@/utils/hasPermission'

// 表头
const columns = [
  {
    title: '机构名称',
    dataIndex: 'departName',
  },
  {
    title: '机构类型',
    align: 'center',
    dataIndex: 'orgType',
  },
  {
    title: '机构编码',
    dataIndex: 'orgCode',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
  },
  {
    title: '传真',
    dataIndex: 'fax',
  },
  {
    title: '地址',
    dataIndex: 'address',
  },
  {
    title: '排序',
    align: 'center',
    dataIndex: 'departOrder',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  name: 'DepartList',
  mixins: [JeecgListMixin],
  components: {
    DepartAuthModal,
    DepartModal,
  },
  data() {
    return {
      iExpandedKeys: [],
      loading: false,
      autoExpandParent: true,
      currFlowId: '',
      currFlowName: '',
      disable: true,
      treeData: [],
      visible: false,
      departTree: [],
      isEditMode: false,
      hiding: true,
      model: {},
      dropTrigger: '',
      depart: {},
      columns: columns,
      disableSubmit: false,
      checkedKeys: [],
      selectedKeys: [],
      autoIncr: 1,
      currSelected: {}, //是treeData中的数据
      nodeIsEdit: false,
      allTreeKeys: [],
      checkStrictly: true,
      platformNodeList: [],
      form: this.$form.createForm(this),
      newInputFocus: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      graphDatasource: {
        nodes: [],
        edges: [],
      },
      validatorRules: {
        departName: { rules: [{ required: true, message: '请输入机构/部门名称!' }] },
        orgCode: { rules: [{ required: true, message: '请输入机构编码!' }] },
        orgCategory: { rules: [{ required: true, message: '请输入机构类型!' }] },
        mobile: { rules: [{ validator: this.validateMobile }] },
      },
      url: {
        delete: '/sys/sysDepart/delete',
        edit: '/sys/sysDepart/edit',
        deleteBatch: '/sys/sysDepart/deleteBatch',
        exportXlsUrl: 'sys/sysDepart/exportXls',
        importExcelUrl: 'sys/sysDepart/importExcel',
      },
      orgCategoryDisabled: false,
    }
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
  },
  methods: {
    loadData() {
      this.refresh()
    },
    loadTree() {
      let that = this
      queryDepartTreeList().then(async (res) => {
        if (res.success) {
          //部门全选后，再添加部门，选中数量增多
          that.allTreeKeys = []
          let treeData = []
          let departTree = []
          for (let i = 0; i < res.data.length; i++) {
            let temp = res.data[i]
            treeData.push(temp)
            departTree.push(temp)
            that.setThisExpandedKeys(temp)
            that.getAllKeys(temp)
            this._addInputTitle(temp, 1)
          }
          let platformList = await this.getPlatformList(this.allTreeKeys.join(','))
          platformList.forEach((one) => {
            let treeData = that.getTreeDataByKey(departTree, one.department.departId)
            if (treeData) {
              treeData.platforms = []
              one.platforms.forEach((two) => {
                that.allTreeKeys.push('platformId_' + two.platformId)
                treeData.platforms.push({ title: two.platformName, key: 'platformId_' + two.platformId })
              })
            }
          })
          that.departTree = departTree
          that.treeData = treeData
          that.loading = false
          that.expandAll()
        }
      })
    },
    _addInputTitle(data, level) {
      if (data) {
        data.inputTitle = data.title
        data.level = level
      }
      if (data.children) {
        data.children.forEach((one) => {
          this._addInputTitle(one, level + 1)
        })
      }
    },
    setThisExpandedKeys(node) {
      if (node.children && node.children.length > 0) {
        this.iExpandedKeys.push(node.key)
        for (let a = 0; a < node.children.length; a++) {
          this.setThisExpandedKeys(node.children[a])
        }
      }
    },
    refresh() {
      this.loading = true
      this.loadTree()
    },
    handleDoubleClickNode(e) {
      if (checkPermission('organization:department:list:button:edit')) {
        this.handleEdit()
      }
    },
    // 横向树右键
    handleRightClick(key) {
      this.currSelected = this.getTreeDataByKey(this.departTree, key)
      if (!this.currSelected) {
        this.currSelected = {}
      } else {
        if (this.currSelected.level <= 3) {
          this.dropTrigger = 'contextmenu'
        }
      }
    },
    // 右键操作方法
    rightClickHandle(node) {
      let record = node.node
      this.currSelected = this.getTreeDataByKey(this.departTree, record.$vnode.key)
      if (!this.currSelected) {
        this.currSelected = {}
      } else {
        if (this.currSelected.level <= 3) {
          this.dropTrigger = 'contextmenu'
        }
      }
    },
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    backFlowList() {
      this.$router.back(-1)
    },
    // 右键点击下拉框改变事件
    dropStatus(visible) {
      if (visible == false) {
        this.dropTrigger = ''
        this.isEditMode = false
      }
    },
    //取消编辑模式
    cancelEditMode() {
      this.isEditMode = false
      this.currSelected.inputTitle = this.currSelected.title
    },
    // 右键店家下拉关闭下拉框
    closeDropdownMenu() {
      this.dropTrigger = ''
    },
    addRootNode() {
      this.$refs.nodeModal.add(this.currFlowId, '')
    },
    batchDel: function () {
      console.log(this.checkedKeys)
      if (this.checkedKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
      } else {
        var ids = ''
        for (var a = 0; a < this.checkedKeys.length; a++) {
          ids += this.checkedKeys[a] + ','
        }
        var that = this
        this.$confirm({
          title: '确认删除',
          content: '确定要删除所选中的 ' + this.checkedKeys.length + ' 条数据，以及子节点数据吗?',
          onOk: function () {
            deleteAction(that.url.deleteBatch, { ids: ids }).then((res) => {
              if (res.success) {
                that.$message.success(res.message)
                that.loadTree()
                that.onClearSelected()
              } else {
                that.$message.warning(res.message)
              }
            })
          },
        })
      }
    },
    onSearch(value) {
      let that = this
      if (value) {
        searchByKeywords({ keyWord: value }).then((res) => {
          if (res.success) {
            that.departTree = []
            for (let i = 0; i < res.data.length; i++) {
              let temp = res.data[i]
              that.departTree.push(temp)
            }
          } else {
            that.$message.warning(res.message)
          }
        })
      } else {
        that.loadTree()
      }
    },
    nodeModalOk() {
      this.loadTree()
    },
    nodeModalClose() {},
    hide() {
      this.visible = false
    },
    getCurrSelectedTitle() {
      return !this.currSelected.title ? '' : this.currSelected.title
    },
    onCheck(checkedKeys, info) {
      console.log('onCheck', checkedKeys, info)
      this.hiding = false
      if (this.checkStrictly) {
        this.checkedKeys = checkedKeys.checked
      } else {
        this.checkedKeys = checkedKeys
      }
    },
    onSelect(selectedKeys, e) {
      this.hiding = false
      let record = e.node
      if (this.currSelected && this.currSelected.key != record.$vnode.key) {
        this.isEditMode = false
      }
      this.currSelected = this.getTreeDataByKey(this.departTree, record.$vnode.key)
      if (!this.currSelected) {
        this.currSelected = {}
      }
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm(record) {
      if (record.orgCategory == '1') {
        this.orgCategoryDisabled = true
      } else {
        this.orgCategoryDisabled = false
      }
      this.$nextTick(() => {
        this.form.getFieldDecorator('fax', { initialValue: '' })
        this.form.setFieldsValue(
          pick(record, 'departName', 'orgCategory', 'orgCode', 'departOrder', 'mobile', 'fax', 'address', 'memo')
        )
      })
    },

    onClearSelected() {
      this.hiding = true
      this.checkedKeys = []
      this.currSelected = {}
      this.form.resetFields()
      this.selectedKeys = []
    },
    submitCurrForm() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.currSelected.id) {
            this.$message.warning('请点击选择要修改部门!')
            return
          }
          let formData = Object.assign(this.currSelected, values)
          console.log('Received values of form: ', formData)
          httpAction(this.url.edit, formData, 'put').then((res) => {
            if (res.success) {
              this.$message.success('保存成功!')
              this.loadTree()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    emptyCurrForm() {
      this.form.resetFields()
    },
    nodeSettingFormSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    openSelect() {
      this.$refs.sysDirectiveModal.show()
    },
    handleAdd(num) {
      if (num == 1) {
        this.$refs.departModal.add()
        this.$refs.departModal.title = '新增'
      } else if (num == 2) {
        let key = this.currSelected.key
        if (!key) {
          this.$message.warning('请先点击选中上级部门！')
          return false
        }
        this.$refs.departModal.add(this.selectedKeys)
        this.$refs.departModal.title = '新增'
      } else {
        // 右键添加子节点
        this.insertNode(this.currSelected.key)
        // this.isEditMode = false
        // this.$refs.departModal.add(this.rightClickSelectedKey)
        // this.$refs.departModal.title = '新增'
      }
    },
    handleDoubleClick(key) {
      if (checkPermission('organization:department:list:button:edit')) {
        this.isEditMode = true
        this.currSelected = this.getTreeDataByKey(this.departTree, key)
        this.handleEdit()
      }
    },
    handleEdit() {
      this.isEditMode = true
      this.dropTrigger = ''
      this.$nextTick((x) => {
        this.$refs.inputNode[0].focus()
      })
    },
    handleDelete() {
      this.closeDropdownMenu()
      var that = this
      this.$confirm({
        title: '确认删除',
        content: '确定要删除此部门以及子节点数据吗?',
        onOk: function () {
          deleteByDepartId({ id: that.currSelected.key }).then((resp) => {
            if (resp.success) {
              //删除成功后，去除已选中中的数据
              that.checkedKeys.splice(
                that.checkedKeys.findIndex((key) => key === that.currSelected.key),
                1
              )
              that.$message.success('删除成功!')
              that.loadTree()
              //删除后同步清空右侧基本信息内容
              let orgCode = that.form.getFieldValue('orgCode')
              if (orgCode && orgCode === that.rightClickSelectedOrgCode) {
                that.onClearSelected()
              }
            } else {
              that.$message.warning('删除失败!')
            }
          })
        },
      })
    },
    selectDirectiveOk(record) {
      console.log('选中指令数据', record)
      this.nodeSettingForm.setFieldsValue({ directiveCode: record.directiveCode })
      this.currSelected.sysCode = record.sysCode
    },
    getFlowGraphData(node) {
      this.graphDatasource.nodes.push({
        id: node.id,
        text: node.flowNodeName,
      })
      if (node.children.length > 0) {
        for (let a = 0; a < node.children.length; a++) {
          let temp = node.children[a]
          this.graphDatasource.edges.push({
            source: node.id,
            target: temp.id,
          })
          this.getFlowGraphData(temp)
        }
      }
    },
    // <!---- author:os_chengtgen -- date:20190827 --  for:切换父子勾选模式 =======------>
    expandAll() {
      this.iExpandedKeys = this.allTreeKeys
    },
    closeAll() {
      this.iExpandedKeys = []
    },
    checkALL() {
      this.checkStriccheckStrictlytly = false
      this.checkedKeys = this.allTreeKeys
    },
    cancelCheckALL() {
      //this.checkedKeys = this.defaultCheckedKeys
      this.checkedKeys = []
    },
    switchCheckStrictly(v) {
      if (v == 1) {
        this.checkStrictly = false
      } else if (v == 2) {
        this.checkStrictly = true
      }
    },
    getAllKeys(node) {
      // console.log('node',node);
      this.allTreeKeys.push(node.key)
      if (node.children && node.children.length > 0) {
        for (let a = 0; a < node.children.length; a++) {
          this.getAllKeys(node.children[a])
        }
      }
    },
    // <!---- author:os_chengtgen -- date:20190827 --  for:切换父子勾选模式 =======------>
    // 根据key获取与之相等的数据对象
    getTreeDataByKey(childs = [], findKey) {
      let finditem = null
      for (let i = 0, len = childs.length; i < len; i++) {
        let item = childs[i]
        if (item.key !== findKey && item.children && item.children.length > 0) {
          finditem = this.getTreeDataByKey(item.children, findKey)
        }
        if (item.key == findKey) {
          finditem = item
        }
        if (finditem != null) {
          break
        }
      }
      return finditem
    },
    // 根据key获取父级节点children数组
    getTreeParentChilds(childs = [], findKey) {
      let parentChilds = []
      for (let i = 0, len = childs.length; i < len; i++) {
        let item = childs[i]
        if (item.key !== findKey && item.children && item.children.length > 0) {
          parentChilds = this.getTreeParentChilds(item.children, findKey)
        }
        if (item.key == findKey) {
          parentChilds = childs
        }
        if (parentChilds.length > 0) {
          break
        }
      }
      return parentChilds
    },

    insertNode(key) {
      let selectItem = this.getTreeDataByKey(this.departTree, key)
      if (!selectItem.children) {
        this.$set(selectItem, 'children', [])
      }
      let maxOrder = 1
      selectItem.children.forEach((one) => {
        if (one.departOrder > maxOrder) {
          maxOrder = one.departOrder
        }
      })
      let newItem = {
        title: '新部门',
        key: Math.random(),
        parentKey: key,
        isInsertNode: true,
        inputTitle: '新部门',
        departOrder: maxOrder + 1,
      }
      selectItem.children.push(newItem)
      this.closeDropdownMenu()
      this.$forceUpdate()
      this.currSelected = newItem
      this.isEditMode = true
      this.$nextTick(() => {
        this.$refs.inputNode[0].focus()
      })
    },
    enterEdit() {
      let key = this.currSelected.key
      let treeData = this.getTreeDataByKey(this.departTree, key)
      if (!treeData) return
      treeData.title = treeData.inputTitle
      let that = this
      if (treeData.isInsertNode) {
        let formData = {
          departName: treeData.title,
          parentId: treeData.parentKey,
          departOrder: treeData.departOrder,
          departOrder: treeData.departOrder || 0,
        }
        httpAction('/sys/sysDepart/add', formData, 'post')
          .then((res) => {
            if (res.success) {
              that.$message.success(res.message)
              that.loadTree()
              that.$emit('ok')
            } else {
              that.$message.warning(res.message)
            }
          })
          .finally(() => {
            that.confirmLoading = false
          })
      } else {
        let formData = {
          id: key,
          departName: treeData.inputTitle,
          departOrder: treeData.departOrder,
        }
        httpAction('/sys/sysDepart/edit', formData, 'put').then((res) => {
          if (res.success) {
            this.$message.success('保存成功!')
            this.loadTree()
          } else {
            this.$message.error(res.message)
          }
        })
      }
      this.cancelEditMode()
    },

    async getPlatformList(key) {
      var res = await getAction('/platform/get_platform_by_departs', { ids: key })
      return res.data
      // if (!res.data.length) return []
      // const { platforms } = res.data[0]
      // var platformList =
      //   platforms.length == 0
      //     ? []
      //     : platforms.map((item) => {
      //         return { title: item.platformName, key: item.platformId }
      //       })
      // return platformList
    },

    isEdited: function (key) {
      if (this.isEditMode) {
        return this.currSelected.key == key
      }
      return false
    },
  },
  created() {
    this.currFlowId = this.$route.params.id
    this.currFlowName = this.$route.params.name
    // this.loadTree()
  },

  mounted() {
    let self = this
    this.$nextTick(function () {
      document.addEventListener('keyup', function (e) {
        if (e.code == 'Escape') {
          self.cancelEditMode()
        }
      })
    })
  },
}
</script>
<style scoped lang="less">
.items-center{
  min-width:250px;
}
/deep/ .ant-input-number-handler-wrap{
  display:none !important;
}
.ant-input-number {
  height: 27px;
}
.ant-card-body .table-operator {
  margin: 15px;
}

.anty-form-btn {
  width: 100%;
  text-align: center;
}

.anty-form-btn button {
  margin: 0 5px;
}

.anty-node-layout .ant-layout-header {
  padding-right: 0;
}

.header {
  padding: 0 8px;
}

.header button {
  margin: 0 3px;
}

.ant-modal-cust-warp {
  height: 100%;
}

.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto;
}

.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden;
}

#app .desktop {
  height: auto !important;
}

/** Button按钮间距 */
.ant-btn {
  margin-left: 3px;
}

.tree-node-text {
  border: 1px solid #ccc;
  // padding: 3px 5px;
  padding: 6px 8px;
  border-radius: 4px;
}

.drawer-bootom-button {
  /*position: absolute;*/
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: left;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
.insert-input {
  border: 1px solid #ddd;
  border-radius: 3px;
}

@page_background: #fff;
@line_color: rgb(77, 75, 75);
@line_width: 1px;

.tree-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: @page_background;

  .tree-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .tree-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .vertical-line {
        width: 0;
        height: 20px;
        border-right: @line_width solid @line_color;
      }

      .content {
        padding: 2px 0 4px 0;
      }

      .root-tree-row {
        .tree-item {
          .vertical-line {
            border-top: none;
          }
        }
      }
    }
  }
  .two-tree-row {
    position: relative;

    &::before {
      content: '';
      width: 100%;
      position: absolute;
      top: 0;
      border-bottom: @line_width solid @line_color;
    }
    .tree-item {
      min-width: 50px;
      position: relative;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
      .content {
        // background-color: rgb(197, 197, 197);
        margin: 0 !important;

        .title {
          .tree-node-text {
            display: block;
          }
        }
      }

      &:first-child {
        align-items: flex-start;

        > .vertical-line {
          width: 50%;
          border-right: @line_width solid @line_color;
          border-top: @line_width solid @page_background;
        }
      }
      &:last-child {
        align-items: flex-end;

        > .vertical-line {
          width: 50%;
          border-right: none;
          border-left: @line_width solid @line_color;
          border-top: @line_width solid @page_background;
        }
      }
    }

    .no-hor-line {
      &::before {
        opacity: 0;
      }
      &:last-child,
      &:first-child {
        .vertical-line {
          border-top: none !important;
        }
      }
    }

    @list_left_width: 6px;
    .platform-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-left: @line_width solid @line_color;
      margin-left: calc(@list_left_width - 1px);

      .platform-item {
        margin-top: 4px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-left: @list_left_width;

        &::before {
          content: '';
          width: @list_left_width;
          height: 0;
          border-bottom: @line_width solid @line_color;
          position: absolute;
          bottom: 2px;
          left: -@list_left_width;
        }

        &:last-child::after {
          content: '';
          width: @list_left_width;
          height: @list_left_width;
          background: @page_background;
          position: absolute;
          bottom: -4px;
          left: calc(-@list_left_width - 2px);
        }
      }
    }
  }
}

// 手动修改树形控件的样式为横向布局
/deep/ .ant-tree {
  display: table;
  margin: 0 auto;
  padding: 10px;

  @line_color: #4d4b4b;
  @line_width: 1px;
  @vertical_line_height: 20px;
  @line_height: 28px;

  ul {
    display: table;
    text-align: center;
    position: relative;
    padding: @vertical_line_height 0 0 0;

    &::before {
      content: '';
      width: 0;
      height: @vertical_line_height;
      border-right: @line_width solid @line_color;
      position: absolute;
      top: 0;
      left: 50%;
    }
  }
  li {
    display: table-cell;
    text-align: center;
    position: relative;
    padding: @vertical_line_height @vertical_line_height 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 20px;
      border-top: @line_width solid transparent;
      pointer-events: none;
    }

    &::after {
      left: 50%;
    }

    &:not(:first-child)::before,
    &:not(:last-child)::after {
      border-color: @line_color;
    }

    & > .ant-tree-switcher {
      display: none;
    }

    & > .ant-tree-node-content-wrapper {
      .items-center input {
        display: block;
        height: 27px;
      }

      &::before {
        content: '';
        width: 0;
        height: @vertical_line_height;
        border-right: @line_width solid @line_color;
        position: absolute;
        top: 0;
        left: 50%;
      }

      &:hover {
        background-color: unset;
      }
    }

    .tree-node-text {
      background: @page_background;
      position: relative;
      z-index: 2;
    }
  }
  & > li {
    padding: unset;

    & > .ant-tree-node-content-wrapper {
      padding: unset;

      &::before {
        content: unset;
      }
    }
  }
  .platforms-tree {
    & > .ant-tree-node-content-wrapper,
    & > .ant-tree-node-content-wrapper span {
      display: block;
      // padding: 0 5px;
      height: unset;
      line-height: unset;
    }

    @list_padding_left_width: 14px;
    @li_left_line_width: 10px;

    & > ul {
      display: block;
      padding: @li_left_line_width 0 0 @list_padding_left_width;

      &::before {
        left: @list_padding_left_width;
        height: 100%;
      }

      & > li {
        display: block;
        text-align: left;
        position: relative;
        margin-top: calc(@li_left_line_width + 5px);
        padding: 0 0 0 calc(@li_left_line_width / 2);

        &::before,
        &::after {
          content: unset;
        }

        &::before {
          content: '';
          width: calc(@li_left_line_width + 5px);
          height: 0;
          border-top: @line_width solid @line_color;
          position: absolute;
          top: 50%;
          left: 0;
        }

        &:last-child::after {
          content: '';
          width: @li_left_line_width;
          height: 50%;
          position: absolute;
          top: calc(50% + @line_width);
          background: @page_background;
          left: calc(-@li_left_line_width / 2);
        }

        & > .ant-tree-node-content-wrapper {
          max-width: 100px;
          white-space: pre-wrap;
          height: unset;
          line-height: unset;

          &::before {
            content: unset;
          }

          span {
            display: block;
          }
        }
      }
    }
  }
}

.first-tree-text {
  font-size: 20px;
  font-weight: bold;
  padding: 7px 20px;
}
.two-tree-row {
  .two-tree-node-text {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 20px;
  }
}

.platforms-tree {
  .platforms-tree-node-text {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>